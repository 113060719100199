import Layout from "../../components/layout";
import Consumer from "../../context";
import {graphql, HeadFC} from "gatsby";
import * as React from "react";
import {Content} from "../../components/content";
import Markdown from "markdown-to-jsx";
import CategoryNav, {CategoryItem} from "../../components/category-nav";
import Testimonial from "../../components/testimonial";
import {SEO} from "../../components/seo";

interface Props {
    data: {
        strapiAgentList: {
            childStrapiAgentListDetailsTextnode: {
                Details: string
            }
        }
    }
}

const AgentsPage = ( {data}: Props) => {

    let cats: CategoryItem[] = [
        {
            title: '合作院校',
            active: false,
            href: '/partnerships/partners'
        },
        {
            title: '官方代表',
            active: true,
            href: '/partnerships/agents'
        },
    ]

    return (
        <Layout>
            <Consumer>
                {({ data, set }) => (
                    (data.category === 'partnership') ? console.log(data.category) :  set({ category: 'partnership' })
                )}
            </Consumer>

            {/*the second menu*/}
            <div className="mt-10">
                <CategoryNav cats={cats} />
            </div>

            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
                <div className="px-4 py-5 flex-auto text-galway-maroon">
                    <article className="
                            prose prose-slate
                            hover:prose-a:text-magenta
                            prose-h2:text-galway-maroon
                            prose-img:border-l-[24px]
                            prose-img:border-l-galway-maroon
                            mx-auto max-w-7xl mt-8"
                        // dangerouslySetInnerHTML={{
                        //     __html: art.content.data.childMarkdownRemark.html,
                        // }}
                    >

                        <Markdown>{data.strapiAgentList.childStrapiAgentListDetailsTextnode.Details}</Markdown>
                    </article>
                </div>
            </div>

        </Layout>
    );
}

export default AgentsPage

export const query = graphql`
    query Agents {
      strapiAgentList {
        childStrapiAgentListDetailsTextnode {
          Details
        }
      }
    }
`

export const Head = () => (
    <SEO title="官方代表" description="爱尔兰高威大学的官方代表" />
)
